@import '~@angular/material/theming';
@include mat-core();

$blue-primary: mat-palette($mat-blue);
$grey-primary: mat-palette($mat-grey);
$pink-primary: mat-palette($mat-pink);
$purple-primary: mat-palette($mat-purple);
$red-primary: mat-palette($mat-red);
$teal-primary: mat-palette($mat-teal);
$yellow-primary: mat-palette($mat-yellow);
$cyan-primary : mat-palette($mat-cyan);

$blue-accent: mat-palette($mat-blue);
$grey-accent: mat-palette($mat-grey);
$pink-accent: mat-palette($mat-pink);
$purple-accent: mat-palette($mat-purple);
$red-accent: mat-palette($mat-red);
$teal-accent: mat-palette($mat-teal);
$yellow-accent: mat-palette($mat-yellow);
$cyan-accent : mat-palette($mat-cyan);

@mixin background($bColor, $fColor) {
    background: $bColor;
    color: $fColor;
}

@mixin font-color($color) {
    color: $color;
}