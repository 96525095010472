/* You can add global styles to this file, and also import other style files */
@import 'assets/custom-theme.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#app-component-container { height: 100%; }

//Core these are needed for the base project to display correctly
// html, body { position: fixed; top: 0;  bottom: 0; left: 0; right: 0; margin: 0; padding: 0; height: 100%; }
// .app-theme-container { display: inherit; }
// .dark-backdrop { background: rgba(0, 0, 0, 0.1); }

//Component modifications ***************************************************************************************
mat-form-field .mat-form-field-prefix, .mat-form-field-suffix { width: 30px; }
snack-bar-container { font-family: Roboto, Arial, sans-serif; }
mat-header-cell, mat-cell { padding-left:10px; }

//Useful classes ************************************************************************************************
.cursor-pointer:hover { cursor: pointer; }
.cursor-pointer:focus { outline: none; }
.display-block { display: block !important; }
.hidden { display:none; }
.fill-remaining-space, .fill-space { flex: 1 1 auto; }
.full-width { width: 100%; }
.base-font { font-family: Roboto, Arial, sans-serif; }
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-bold { font-weight: bold; }
.padding { padding: 10px; }
.padding-top { padding-top: 10px; }
.padding-right { padding-right: 10px; }
.padding-bottom { padding-bottom: 10px; }
.padding-left { padding-left: 10px; }
.no-padding { padding: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding-left { padding-left: 0 !important; }
.margin { margin: 10px; }
.margin-top { margin-top: 10px; }
.margin-right { margin-right: 10px; }
.margin-bottom { margin-bottom: 10px; }
.margin-left { margin-left: 10px; }
.no-margin { margin: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.no-margin-right { margin-right: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.text-overflow { white-space: nowrap; text-overflow: ellipsis; display: block; overflow: hidden; }
.overflow-y { overflow-y: auto; }
.overflow-x { overflow-x: auto; }
.hidden-scrollbar::-webkit-scrollbar { display: none; }
.pull-right { float: right; }
.pull-left { float: left; }
.smaller-text { font-size: 0.8em; }
.page-container { padding: 20px 100px 0 100px; }
@media only screen and (max-width: 768px) {
  .page-container { padding: 0; padding-top: 10px; }
}
//Angular Material Customizations ***************************************************************************************************
::-webkit-scrollbar { width: 5px; }
::-webkit-scrollbar:horizontal { height: 5px; }
::-webkit-scrollbar-track { background: #f1f1f1;  }
::-webkit-scrollbar-thumb { background: #888;  }
::-webkit-scrollbar-thumb:hover { background: #555;  }
.search-div{ margin-bottom: 10px; }
.search-form-field { flex: 0 0 calc(100% - 85px); }
.form-dialog mat-dialog-container { padding: 0px; }
.mat-column-actions { flex: 0 0 100px; }
mat-dialog-container mat-toolbar { height: 48px !important; }
mat-dialog-container form { padding-left: 30px !important; padding-right: 30px !important; padding-bottom: 10px !important; padding-top: 10px !important; }
.icon-button mat-icon { transform: translateY(-1px); }
//Custom Scrollbar
/* width */
.custom-scrollbar::-webkit-scrollbar { width: 5px; }
/* Track */
.custom-scrollbar::-webkit-scrollbar-track { background: #f1f1f1;  }
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb { background: #888;  }
/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover { background: #555;  }
.top-right-button { height: 48px; }
mat-footer-row, mat-row { min-height: 40px !important; }

// for this project
tr.mat-row { height: 40px !important; }
.bus-marker {
  // background-image: url('/assets/img/bus.png');
  background-image: url('/assets/img/busRedPlus.png');
  height:50px;
  width:50px;
  background-size: contain;
  z-index:99;
}
.outofroute-marker {
  height:13px;
  width:13px;
  border-radius:13px;
  background: rgba(255,0,0,0.6);
}
.small-waypoint-marker {
  height:4px;
  width:4px;
  border-radius:4px;
  background: rgb(0,0,0);
}
.error-waypoint-marker {
  height:4px;
  width:4px;
  border-radius:4px;
  background: rgb(0,0,0);
}
.waypoint-marker {
  height:20px;
  width:20px;
  border-radius:20px;
  padding-left:12px;
  padding-top:12px;
}
.inner-waypoint-marker {
  display: block;
  height:10px;
  width:10px;
  border-radius: 10px;
  background: black;
}
mat-toolbar mat-icon {
  transform: translateY(5px);
}
.sub-toolbar {
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px;
}
.in-form-container {
  border:1px solid rgba(255, 255, 255, 0.7); 
  border-radius:5px; 
  padding: 10px;
}
.fixed-icon {
  transform: translateY(5px);
}
.mapboxgl-popup-content {
  padding: 5px;
  color: black;
}