@import '~@angular/material/theming';
@include mat-core();
@import './variables.scss';

@include mat-core();

$app-dark-primary: mat-palette($mat-green, A200);
$app-dark-accent: mat-palette($mat-purple, A700);
$app-dark-warn: mat-palette($mat-red, A400);

$app-light-primary: mat-palette($mat-orange, 600);
$app-light-accent: mat-palette($mat-blue, 800);
$app-light-warn: mat-palette($mat-red, 500);

$app-dark-theme: mat-dark-theme($app-dark-primary, $app-dark-accent, $app-dark-warn);
$app-light-theme: mat-light-theme($app-light-primary, $app-light-accent, $app-light-warn);


.dark-theme {
  @include angular-material-theme($app-dark-theme);
  background: #2b2b2b;
  color: #fff;

  // ::ng-deep snack-bar-container.success-snackbar { background: map-get($mat-green, A200); color: #fff; }
  // ::ng-deep snack-bar-container.error-snackbar { background: map-get($mat-red, A400); color: #fff; }
  .selected-background {
    background-color: #444;
  }
  
  // .base-background {
  //   @include background(map-get($mat-dark-theme-background, 'background'), map-get($mat-dark-theme-foreground, 'text'));
  // }

  snack-bar-container.success {
    @include background(map-get($mat-blue, 500), #fff);
  }

  snack-bar-container.warning {
    @include background(map-get($mat-red, 500), #fff);
  }

  .selected-route {
    box-shadow: inset 100px 100px rgba(255, 255, 255, 0.1);
  }

  .font-primary {
    @include font-color(map-get($mat-blue, 500));
  }

  .background-base {
    background-color: (map_get($mat-grey, 800));
  }

  .dialog-container {
    background-color: #2b2b2b;
  }

  .darker-background {
    background-color:rgba(0, 0, 0, 0.2);
  }
}

.light-theme {
  @include angular-material-theme($app-light-theme);
  #main-content {
    background: #f2f2f2;
    color: #222;
  }
  
  .selected-background {
    background-color: #ddd;
  }

  .darker-background {
    background-color:rgba(0, 0, 0, 0.1);
  }
}

